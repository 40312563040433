import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const Icons = React.lazy(() => import('./views/icons/coreui-icons/CoreUIIcons'))
const Dropdowns = React.lazy(() => import('./views/buttons/dropdowns/Dropdowns'))

// Pages
const Login = React.lazy(() => import('./Login'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="*" name="Login" element={<Login />} />
            <Route exact path="/icons" name="Icons" element={<Icons />} />
            <Route exact path="/dropdowns" name="Dropdowns" element={<Dropdowns />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
